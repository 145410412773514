import React, { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import "../Styles/Login.css";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Signup } from '../Services/Operations/ProductAPI';
import { useDispatch } from 'react-redux';
import { setSignupData, setToken } from '../Redux/user_information';

const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { register, reset, handleSubmit, formState: { isSubmitSuccessful, errors } } = useForm();

    const onSubmit = async (data) => {
        try {
            const result = await Signup(data);
            if (result.success === true) {
                dispatch(setToken(result.userdata.access_token)); 
                dispatch(setSignupData(result.userdata));
                navigate("/otp", { state: { from: location.state?.from || "/" } });
            } 
        } catch (error) {
            // Handle error (optional)
        }
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({ email: "" });
        }
    }, [isSubmitSuccessful, reset]);

    return (
        <div className='login-wrapper'>
            <div className='login-container'>
                <div className='login-form'>
                    <h5>Signup/Login</h5>
                    <form className='form' onSubmit={handleSubmit(onSubmit)}>
                        <div className='login-phone'>
                            <label htmlFor='email'>Enter Your Email</label>
                            <input
                                type='email'
                                id='email'
                                placeholder='Enter Your Email'
                                className='login-input'
                                {...register('email', { 
                                    required: 'Email is required', 
                                    pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email address' } 
                                })}
                            />
                            {errors.email && <p>{errors.email.message}</p>}
                        </div>
                        <button className='form-btn' type='submit'>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Register;
