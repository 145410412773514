import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../Styles/Checkout.css";
import { Navbar } from "../../Core/Navbar";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  checkCoupon,
  getLogout,
  getState,
  orderCreate,
  orderValidate,
  orderValidateCod,
  placeOrder,
} from "../../Services/Operations/ProductAPI";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { logout } from "../../Redux/user_information";
import { toast } from "react-toastify";
import {
  setCheckoutCartItems,
  setCheckoutProductItems,
} from "../../Redux/Cart_System";

export const CheckOut = () => {
  const location = useLocation();
  const cartItems = location.state?.cartItems || [];
  const productItems = location.state?.product_details || {};
  const signupData = useSelector((state) => state.user.signupData);
  const { token } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [error, setError] = useState("");

  const handleApply = async () => {
    try {
      const response = await checkCoupon({ coupon_code: couponCode });
      console.log("Coupon : ", response);
      if (response.success) {
        setDiscount(0.1);
        setError("");
      } else {
        setDiscount(0);
        setError("Coupon is not valid");
      }
    } catch (error) {
      console.error("Error applying coupon:", error);
    }
  };

  useEffect(() => {
    console.log("cartItems from product : ", cartItems);
    console.log("productItems from product : ", productItems);
    if (cartItems.length > 0) {
      console.log("............cart...........");
      dispatch(setCheckoutCartItems(cartItems));
    }

    if (Object.keys(productItems).length > 0) {
      dispatch(setCheckoutProductItems(productItems));
      console.log("............product...........");
    }
  }, [cartItems, productItems, dispatch]);
  console.log("checkout cart Item : ", productItems);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState([]);
  const [pinCode, setPinCode] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [billingAddressSame, setBillingAddressSame] = useState(true);
  const [saveInfo, setSaveInfo] = useState(false);
  const [selectedState, setSelectedState] = useState("1");

  const [billingFirstName, setBillingFirstName] = useState("");
  const [billingLastName, setBillingLastName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingState, setBillingState] = useState("1");
  const [billingPinCode, setBillingPinCode] = useState("");
  const [billingPhone, setBillingPhone] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("ONLINE");
  const [showLogout, setShowLogout] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const cartItemsFromRedux = useSelector((state) => state.cart.cartItems);
  const productItemsFromRedux = useSelector((state) => state.cart.productItems);

  console.log("cartItemsFromRedux : ", cartItemsFromRedux);
  console.log("productItemsFromRedux : ", productItemsFromRedux);

  const withoutDiscounttotal = cartItemsFromRedux.reduce(
    (acc, item) => acc + item.mrp * item.quantity,
    0
  );
  const total = discount
    ? withoutDiscounttotal - withoutDiscounttotal * discount
    : withoutDiscounttotal;
  const withoutDiscounttotal1 =
    productItemsFromRedux.mrp * productItemsFromRedux.quantity;
  const total1 = discount
    ? withoutDiscounttotal1 - withoutDiscounttotal1 * discount
    : withoutDiscounttotal1;

  console.log("total : ", total);

  console.log("total1 : ", total1);

  const toggleLogoutSection = () => {
    setShowLogout((prev) => !prev);
  };

  const navigate = useNavigate();

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    pinCode: "",
    phone: "",
    billingFirstName: "",
    billingLastName: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingPinCode: "",
    billingPhone: "",
    billingCountry: "",
  });

  const navigation = useNavigate();
  const validateForm = () => {
    const newErrors = {
      firstName: firstName ? "" : "First name is required",
      lastName: lastName ? "" : "Last name is required",
      address: address ? "" : "Address is required",
      city: city ? "" : "City is required",
      pinCode: pinCode ? "" : "PIN code is required",
      phone: phone
        ? phone.length === 10
          ? ""
          : "Phone number must be exactly 10 digits"
        : "Phone number is required",
      // billingFirstName:
      //   !billingAddressSame && !billingFirstName
      //     ? "First name is required"
      //     : "",
      // billingLastName:
      //   !billingAddressSame && !billingLastName ? "Last name is required" : "",
      // billingAddress:
      //   !billingAddressSame && !billingAddress ? "Address is required" : "",
      // billingCity:
      //   !billingAddressSame && !billingCity ? "City is required" : "",
      // billingPinCode:
      //   !billingAddressSame && !billingPinCode ? "PIN code is required" : "",
      // billingPhone:
      //   !billingAddressSame && !billingPhone
      //     ? billingPhone.length === 10
      //       ? ""
      //       : "Phone number must be exactly 10 digits"
      //     : "Phone number is required",
      // billingCountry:
      //   !billingAddressSame && !billingCountry ? "Country is required" : "",
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (signupData) {
      console.log("...............", validateForm());
      if (validateForm()) {
        const formattedCartItems1 = [
          {
            product_id: productItemsFromRedux.id,
            size_id: productItemsFromRedux.size_id,
            quantity: productItemsFromRedux.quantity,
            price: productItemsFromRedux.mrp,
          },
        ];
        console.log("formattedCartItems1 : ", formattedCartItems1);
        const formattedCartItems = cartItemsFromRedux.map((item) => ({
          product_id: item.id,
          size_id: item.size_id,
          quantity: item.quantity,
          price: item.mrp,
        }));
        console.log("formattedCartItems : ", formattedCartItems);

        const formData = {
          first_name: firstName,
          last_name: lastName,
          address,
          city,
          payment_type: paymentMethod,
          state_id: selectedState,
          pin_code: pinCode,
          contact: phone,
          country: "India",
          cart_items:
            formattedCartItems.length > 0
              ? formattedCartItems
              : formattedCartItems1,
          access_token: token,
          subtotal: total ? total : total1,
          grand_total: total ? total : total1,
        };

        console.log("Form data:", formData);
        try {
          //console.log("paymentMethod : ", paymentMethod);
          if (paymentMethod === "COD") {
            const response1 = await placeOrder(formData);
            if (response1.status === "out_of_stock") {
              toast.error("Items is out_of_stock");
              return;
            }
            handlerPaymentCod(formData);
          } else {
            handlerPayment(formData);
          }
        } catch (error) {
          console.error("Error get profile:", error);
        }
      }
    } else {
      navigate("/register", { state: { from: "/checkout" } });
    }
    setIsLoading(false);
  };

  const handleCheckboxChange = () => {
    setSaveInfo((prevState) => !prevState);
  };

  const handleLogOutfromLogin = async () => {
    try {
      const response = await getLogout({ access_token: token });
      if (response.success === true) {
        toast.error("User logged out successfully");
        dispatch(logout());
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    const userState = async () => {
      try {
        const response = await getState();
        //console.log("state : ", response.all_states);
        setState(response.all_states);
      } catch (error) {
        console.error("Error get profile:", error);
      }
    };
    userState();
  }, []);

  const handlerPaymentCod = async (data) => {
    try {
      console.log("data : ", data);

      const result = await orderCreate(data);

      console.log("result : ", result);
      var options = {
        key: result.data.key,
        amount: result.data.amount_due,
        currency: "INR",
        name: "Ecomerse Website",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: result.data.razorpay_order_id,
        handler: async function (response) {
          try {
            const validationResponse = await orderValidateCod({
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });

            //console.log("validationResponse : ",validationResponse)

            if (validationResponse.success === true) {
              toast.success("Order is successfully completed");
              navigate("/profile", { state: { showOrder: true } });
              console.log(".............payment successful................");
            } else {
              alert("Payment validation failed. Please contact support.");
            }
          } catch (error) {
            console.error("Error validating payment:", error);
          }
        },
        prefill: {
          name: "Saifuddin Mondal",
          email: "saifuddin@example.com",
          contact: "9564779055",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      alert("Error creating order. Please try again.");
      console.error(error);
    }
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlerPayment = async (data) => {
    try {
      console.log("data : ", data);

      const result = await orderCreate(data);
      console.log("result : ", result);

      if (result.status === "out_of_stock") {
        toast.error("Items is out of stock");
        return;
      }

      // Ensure Razorpay script is loaded
      const isRazorpayLoaded = await loadRazorpayScript();
      if (!isRazorpayLoaded) {
        alert("Failed to load Razorpay SDK. Please try again.");
        return;
      }

      const options = {
        key: result.data.key,
        amount: result.data.amount,
        currency: "INR",
        name: "Ecommerce Website",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: result.data.order_id,
        handler: async function (response) {
          try {
            const validationResponse = await orderValidate({
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });

            if (validationResponse.success === true) {
              toast.success("Order is successfully completed");
              navigate("/profile", { state: { showOrder: true } });
            } else {
              alert("Payment validation failed. Please contact support.");
            }
          } catch (error) {
            console.error("Error validating payment:", error);
          }
        },
        prefill: {
          name: "Saifuddin Mondal",
          email: "saifuddin@example.com",
          contact: "9564779055",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      alert("Error creating order. Please try again.");
      console.error(error);
    }
  };

  return (
    <div className="checkout-container">
      <Navbar
        color="black"
        src1={require("../../../Assets/Img/Vector(1).png")}
        src2={require("../../../Assets/Img/material-symbols-light_search(1).png")}
        src3={require("../../../Assets/Img/solar_cart-outline(1).png")}
      />
      <div className="checkout-wrapper">
        <div>
          {signupData ? (
            <div className="Contact">
              <div className="account-info">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   toggleLogoutSection()
                  // }}
                >
                  <p style={{ marginBottom: "0px", marginTop: "16px" }}>
                    Account
                  </p>
                  <span>
                    {showLogout ? (
                      <FaAngleUp
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleLogoutSection();
                        }}
                      />
                    ) : (
                      <FaAngleDown
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleLogoutSection();
                        }}
                      />
                    )}
                  </span>
                </div>
                <p style={{ marginBottom: "0px" }} className="email">
                  {signupData.email}
                </p>
                {showLogout && (
                  <Link to="#" onClick={handleLogOutfromLogin}>
                    Log out
                  </Link>
                )}
              </div>
            </div>
          ) : (
            <div
              className="login-form"
              style={{
                gap: "8px",
                maxWidth: "800px",
                backgroundColor: "#f9f9f9",
                boxShadow: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "28px",
                }}
              >
                <h3 style={{ marginBottom: "0px", fontSize: "1.5em" }}>
                  Contact
                </h3>
                <button
                  className="login-button"
                  onClick={() =>
                    navigation("/register", { state: { from: "/checkout" } })
                  }
                >
                  Log in
                </button>
              </div>
              <input
                className="email-input"
                type="email"
                placeholder="Email or mobile phone number"
                style={{ margin: "5px 0px 9px 0px" }}
              />
              {/* <p className="error-message">Enter an email or phone number</p> */}
              <div
                className="checkbox-container"
                style={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  width: "100%",
                }}
              >
                <input type="checkbox" id="offers" style={{ margin: "0px" }} />
                <label htmlFor="offers">Email me with news and offers</label>
              </div>
            </div>
          )}
          <div className="section">
            <h2>Delivery</h2>
            <form
              className="form"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="form-group">
                <label htmlFor="country">Country/Region</label>
                <input id="country" type="text" value="India" readOnly />
              </div>

              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  placeholder="First name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  onBlur={() =>
                    !firstName &&
                    setErrors((prev) => ({
                      ...prev,
                      firstName: "First name is required",
                    }))
                  }
                />
                {errors.firstName && (
                  <span className="error">{errors.firstName}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  onBlur={() =>
                    !lastName &&
                    setErrors((prev) => ({
                      ...prev,
                      lastName: "Last name is required",
                    }))
                  }
                />
                {errors.lastName && (
                  <span className="error">{errors.lastName}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  id="address"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  onBlur={() =>
                    !address &&
                    setErrors((prev) => ({
                      ...prev,
                      address: "Address is required",
                    }))
                  }
                />
                {errors.address && (
                  <span className="error">{errors.address}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="apartment">
                  Apartment, suite, etc. (optional)
                </label>
                <input
                  type="text"
                  id="apartment"
                  placeholder="Apartment, suite, etc."
                />
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    id="city"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    onBlur={() =>
                      !city &&
                      setErrors((prev) => ({
                        ...prev,
                        city: "City is required",
                      }))
                    }
                  />
                  {errors.city && <span className="error">{errors.city}</span>}
                </div>
                <div className="form-group">
                  <label htmlFor="state">State</label>
                  <select
                    id="state"
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                  >
                    {/* <option value="">Select a state</option> */}
                    {state.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.state_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="pinCode">PIN code</label>
                  <input
                    type="text"
                    id="pinCode"
                    placeholder="PIN code"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    onBlur={() =>
                      !pinCode &&
                      setErrors((prev) => ({
                        ...prev,
                        pinCode: "PIN code is required",
                      }))
                    }
                  />
                  {errors.pinCode && (
                    <span className="error">{errors.pinCode}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  id="phone"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onBlur={() =>
                    !phone &&
                    setErrors((prev) => ({
                      ...prev,
                      phone: "Phone number is required",
                    }))
                  }
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                }}
              >
                <input
                  type="checkbox"
                  id="saveInfo"
                  checked={saveInfo}
                  onChange={handleCheckboxChange}
                  style={{ width: "auto" }}
                />
                <label htmlFor="saveInfo">
                  Save this information for next time
                </label>
              </div>
            </form>
          </div>
          <div className="section">
            <h2>Payment</h2>

            <div
              className="form-group"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <input
                type="radio"
                id="razorpay"
                name="payment"
                value="ONLINE"
                defaultChecked
                onChange={handlePaymentChange}
                style={{ width: "auto" }}
              />
              <label htmlFor="razorpay">
                Razorpay Secure (UPI, Cards, Wallets, NetBanking)
              </label>
            </div>

            <div
              className="form-group"
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "10px",
                flexDirection: "column", // This will make the note appear below the radio button and label
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <input
                  type="radio"
                  id="COD"
                  name="payment"
                  value="COD"
                  onChange={handlePaymentChange}
                  style={{ width: "auto" }}
                />
                <label htmlFor="COD">Cash on Delivery (COD)</label>
              </div>
              <p style={{ fontSize: "14px", color: "#555", margin: "0" }}>
                (Note: Choosing COD requires a 10% advance payment.)
              </p>
            </div>
          </div>

          <div className="section">
            <h2>Billing address</h2>
            <div
              className="form-group"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <input
                type="radio"
                id="sameAsShipping"
                checked={billingAddressSame}
                onChange={() => setBillingAddressSame(true)}
                style={{ width: "auto" }}
              />
              <label htmlFor="sameAsShipping">Same as shipping address</label>
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <input
                type="radio"
                id="differentBilling"
                checked={!billingAddressSame}
                onChange={() => setBillingAddressSame(false)}
                style={{ width: "auto" }}
              />
              <label htmlFor="differentBilling">
                Use a different billing address
              </label>
            </div>
          </div>

          {!billingAddressSame && (
            <div className="billing-address" style={{ padding: "0px 35px" }}>
              <h3>Billing Address</h3>
              <div className="form-group">
                <label htmlFor="billingFirstName">First Name</label>
                <input
                  type="text"
                  id="billingFirstName"
                  placeholder="First name"
                  value={billingFirstName}
                  onChange={(e) => setBillingFirstName(e.target.value)}
                  onBlur={() =>
                    !billingFirstName &&
                    setErrors((prev) => ({
                      ...prev,
                      billingFirstName: "First name is required",
                    }))
                  }
                />
                {errors.billingFirstName && (
                  <span className="error">{errors.billingFirstName}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="billingLastName">Last Name</label>
                <input
                  type="text"
                  id="billingLastName"
                  placeholder="Last name"
                  value={billingLastName}
                  onChange={(e) => setBillingLastName(e.target.value)}
                  onBlur={() =>
                    !billingLastName &&
                    setErrors((prev) => ({
                      ...prev,
                      billingLastName: "Last name is required",
                    }))
                  }
                />
                {errors.billingLastName && (
                  <span className="error">{errors.billingLastName}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="billingAddress">Address</label>
                <input
                  type="text"
                  id="billingAddress"
                  placeholder="Address"
                  value={billingAddress}
                  onChange={(e) => setBillingAddress(e.target.value)}
                  onBlur={() =>
                    !billingAddress &&
                    setErrors((prev) => ({
                      ...prev,
                      billingAddress: "Address is required",
                    }))
                  }
                />
                {errors.billingAddress && (
                  <span className="error">{errors.billingAddress}</span>
                )}
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="billingCity">City</label>
                  <input
                    type="text"
                    id="billingCity"
                    placeholder="City"
                    value={billingCity}
                    onChange={(e) => setBillingCity(e.target.value)}
                    onBlur={() =>
                      !billingCity &&
                      setErrors((prev) => ({
                        ...prev,
                        billingCity: "City is required",
                      }))
                    }
                  />
                  {errors.billingCity && (
                    <span className="error">{errors.billingCity}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="billingState">State</label>
                  <select
                    id="billingState"
                    value={billingState}
                    onChange={(e) => setBillingState(e.target.value)}
                  >
                    {/* <option value="">Select State</option> */}
                    {state.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.state_name}
                      </option>
                    ))}
                  </select>
                  {errors.billingState && (
                    <span className="error">{errors.billingState}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="billingPinCode">PIN code</label>
                  <input
                    type="text"
                    id="billingPinCode"
                    placeholder="PIN code"
                    value={billingPinCode}
                    onChange={(e) => setBillingPinCode(e.target.value)}
                    onBlur={() =>
                      !billingPinCode &&
                      setErrors((prev) => ({
                        ...prev,
                        billingPinCode: "PIN code is required",
                      }))
                    }
                  />
                  {errors.billingPinCode && (
                    <span className="error">{errors.billingPinCode}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="billingPhone">Phone Number</label>
                <input
                  type="text"
                  id="billingPhone"
                  placeholder="Phone number"
                  value={billingPhone}
                  onChange={(e) => setBillingPhone(e.target.value)}
                  onBlur={() =>
                    !billingPhone &&
                    setErrors((prev) => ({
                      ...prev,
                      billingPhone: "Phone number is required",
                    }))
                  }
                />
                {errors.billingPhone && (
                  <span className="error">{errors.billingPhone}</span>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="order-create-section">
          {cartItemsFromRedux.length > 0 ? (
            <div className="order-summary">
              <h2>Order Summary</h2>
              {cartItemsFromRedux.map((item) => (
                <div
                  key={item.id}
                  className="cart-item"
                  style={{
                    border: "1px solid #d6cdcd",
                    borderRadius: "11px",
                    marginBottom: "17px",
                    padding: "0px 11px",
                  }}
                >
                  <img
                    src={`https://sianceedu.com/overbar/uploads/product_banner/${item.product_banner}`}
                    alt={item.product_name}
                    className="cart-item-image"
                  />
                  <div style={{ marginTop: "10px", width: "58%" }}>
                    <div
                      className="cart-item-details"
                      style={{ fontFamily: "Arboria" }}
                    >
                      <h4>{item.product_name}</h4>
                      <p>Size: {item.size}</p>
                    </div>
                  </div>
                  <div
                    className="cart-item-price"
                    style={{ fontFamily: "Arboria" }}
                  >
                    ₹ {item.mrp * item.quantity}
                  </div>
                </div>
              ))}

              <div className="summary-item">
                <input
                  type="text"
                  placeholder="Discount code"
                  value={couponCode}
                  onChange={(e) => {
                    setCouponCode(e.target.value);
                    if (e.target.value === "") {
                      setError("");
                    }
                  }}
                />
                <button onClick={handleApply}>Apply</button>
              </div>
              {error && <div className="error-message">{error}</div>}

              <div className="summary-item">
                <span>
                  Subtotal{discount > 0 && <span>(10% Discount)</span>}
                </span>
                <span>{total}</span>
              </div>
              <div className="summary-item">
                <span>Shipping</span>
                <span>Enter shipping address</span>
              </div>
              <div className="summary-item">
                <span>Total (Including ₹287.75 in taxes)</span>
                <span>{total}</span>
              </div>
            </div>
          ) : productItemsFromRedux ? (
            <div className="order-summary">
              <h2>Product Details</h2>
              <div
                key={productItemsFromRedux.id}
                className="cart-item"
                style={{
                  border: "1px solid #d6cdcd",
                  borderRadius: "11px",
                  marginBottom: "17px",
                  padding: "0px 11px",
                }}
              >
                <img
                  src={`https://sianceedu.com/overbar/uploads/product_banner/${productItemsFromRedux.product_banner}`}
                  alt={productItemsFromRedux.product_name}
                  className="cart-item-image"
                />
                <div style={{ marginTop: "10px", width: "58%" }}>
                  <div
                    className="cart-item-details"
                    style={{ fontFamily: "Arboria" }}
                  >
                    <h4>{productItemsFromRedux.product_name}</h4>
                    <p>Size: {productItemsFromRedux.size}</p>
                  </div>
                </div>
                <div
                  className="cart-item-price"
                  style={{ fontFamily: "Arboria" }}
                >
                  ₹ {productItemsFromRedux.mrp * productItemsFromRedux.quantity}
                </div>
              </div>
              <div className="summary-item">
                <input
                  type="text"
                  placeholder="Discount code"
                  value={couponCode}
                  onChange={(e) => {
                    setCouponCode(e.target.value);
                    if (e.target.value === "") {
                      setError("");
                    }
                  }}
                />
                <button onClick={handleApply}>Apply</button>
              </div>
              {error && <div className="error-message">{error}</div>}
              <div className="summary-item">
                <span>
                  Subtotal{discount > 0 && <span>(10% Discount)</span>}
                </span>
                <span>{total1}</span>
              </div>
              <div className="summary-item">
                <span>Shipping</span>
                <span>Enter shipping address</span>
              </div>
              <div className="summary-item">
                <span>Total (Including ₹287.75 in taxes)</span>
                <span>{total1}</span>
              </div>
            </div>
          ) : (
            <div className="empty-product">
              <h2>No product details available.</h2>
            </div>
          )}
        </div>
      </div>
      <button className="pay-now-btn" onClick={onSubmit} disabled={isLoading}>
        {isLoading ? "Processing..." : "Pay Now"}
      </button>
    </div>
  );
};
