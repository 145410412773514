import React from "react";
import "../Styles/policy.css";
import { Navbar } from "../Core/Navbar";

const StorePolicy = () => {
  return (
    <div>
      <Navbar
        color="black"
        src1={require("../../Assets/Img/Vector(1).png")}
        src2={require("../../Assets/Img/material-symbols-light_search(1).png")}
        src3={require("../../Assets/Img/solar_cart-outline(1).png")}
      />
      <div className="store-policy-container">
        <h4 style={{textAlign:"center",marginBottom: "34px",textTransform: "capitalize"}}>Privacy policy</h4>
        <p>
          [NOTE TO MERCHANT: This is a template Privacy Policy designed to cover
          your collection, use, and disclosure of personal information about
          visitors and customers of your Shopify-powered Site. Please review and
          customize the content so that it is tailored to your store and
          business practices regarding how you collect, use, and disclose
          personal data. For example, you may need to update the template
          content if: you are based in certain jurisdictions (particularly to
          reflect certain disclosures that are required under GDPR), you collect
          information from other sources like third parties or offline; you
          disclose information to additional service providers or business
          partners; or you add third-party cookies or other analytics tools.
          Please also remember to delete all "Note to Merchant" drafting notes
          prior to publishing.]
        </p>
        <p>
          This template is not legal advice, and you are solely responsible for
          ensuring that you meet your obligations under applicable laws. As
          privacy laws are constantly evolving, you should regularly review your
          privacy notice to ensure that it is compliant with updated laws and
          regulation and that it accurately reflects current data handling
          practices. We recommend that you consult a lawyer as needed.
        </p>
        <p>Last updated: [Date]</p>
        <p>
          This Privacy Policy describes how Overbar (the "Site", "we", "us", or
          "our") collects, uses, and discloses your personal information when
          you visit, use our services, or make a purchase from 'overbar.in' (the
          "Site") or otherwise communicate with us (collectively, the
          "Services"). For purposes of this Privacy Policy, "you" and "your"
          means you as the user of the Services, whether you are a customer,
          website visitor, or another individual whose information we have
          collected pursuant to this Privacy Policy.
        </p>
        <p>
          Please read this Privacy Policy carefully. By using and accessing any
          of the Services, you agree to the collection, use, and disclosure of
          your information as described in this Privacy Policy. If you do not
          agree to this Privacy Policy, please do not use or access any of the
          Services.
        </p>
        <h4>Changes to This Privacy Policy</h4>
        <p>
          We may update this Privacy Policy from time to time, including to
          reflect changes to our practices or for other operational, legal, or
          regulatory reasons. We will post the revised Privacy Policy on the
          Site, update the "Last updated" date and take any other steps required
          by applicable law.
        </p>
        <h4>How We Collect and Use Your Personal Information</h4>
        <p>
          To provide the Services, we collect and have collected over the past
          12 months personal information about you from a variety of sources, as
          set out below. The information that we collect and use varies
          depending on how you interact with us.
        </p>
        <p>
          In addition to the specific uses set out below, we may use information
          we collect about you to communicate with you, provide the Services,
          comply with any applicable legal obligations, enforce any applicable
          terms of service, and to protect or defend the Services, our rights,
          and the rights of our users or others.
        </p>
        <h4>What Personal Information We Collect</h4>
        <p>
          The types of personal information we obtain about you depends on how
          you interact with our Site and use our Services. When we use the term
          "personal information", we are referring to information that
          identifies, relates to, describes or can be associated with you. The
          following sections describe the categories and specific types of
          personal information we collect.
        </p>
        <h5>Information We Collect Directly from You</h5>
        <h4>
          Information that you directly submit to us through our Services may
          include:
        </h4>
        <h4>Basic contact</h4> details including your name, address, phone
        number, email.
        <h4>Order information</h4> including your name, billing address,
        shipping address, payment confirmation, email address, phone number.
        <h4>Account information</h4> including your username, password, security
        questions.
        <h4>Shopping information</h4> including the items you view, put in your
        cart or add to your wishlist.
        <h4>Customer support information</h4> including the information you
        choose to include in communications with us, for example, when sending a
        message through the Services.
        <h5>
          Some features of the Services may require you to directly provide us
          with certain information about yourself. You may elect not to provide
          this information, but doing so may prevent you from using or accessing
          these features.
        </h5>
        <h5>Information We Collect through Cookies</h5>
        <p>
          We also automatically collect certain information about your
          interaction with the Services ("Usage Data"). To do this, we may use
          cookies, pixels and similar technologies ("Cookies"). Usage Data may
          include information about how you access and use our Site and your
          account, including device information, browser information,
          information about your network connection, your IP address and other
          information regarding your interaction with the Services.
        </p>
        <h5>Information We Obtain from Third Parties</h5>
        Finally, we may obtain information about you from third parties,
        including from vendors and service providers who may collect information
        on our behalf, such as:
        <p>Companies who support our Site and Services, such as Shopify.</p>
        <p>
          Our payment processors, who collect payment information (e.g., bank
          account, credit or debit card information, billing address) to process
          your payment in order to fulfill your orders and provide you with
          products or services you have requested, in order to perform our
          contract with you.
        </p>
        <p>
          When you visit our Site, open or click on emails we send you, or
          interact with our Services or advertisements, we, or third parties we
          work with, may automatically collect certain information using online
          tracking technologies such as pixels, web beacons, software developer
          kits, third-party libraries, and cookies.
        </p>
        <p>
          Any information we obtain from third parties will be treated in
          accordance with this Privacy Policy. We are not responsible or liable
          for the accuracy of the information provided to us by third parties
          and are not responsible for any third party's policies or practices.
          For more information, see the section below, Third Party Websites and
          Links.
        </p>
        <h5>How We Use Your Personal Information</h5>
        <h5>Providing Products and Services.</h5>{" "}
        <p>
          We use your personal information to provide you with the Services in
          order to perform our contract with you, including to process your
          payments, fulfill your orders, to send notifications to you related to
          you account, purchases, returns, exchanges or other transactions, to
          create, maintain and otherwise manage your account, to arrange for
          shipping, facilitate any returns and exchanges and to enable you to
          post reviews.
        </p>
        <h5>Marketing and Advertising.</h5>{" "}
        <p>
          We use your personal information for marketing and promotional
          purposes, such as to send marketing, advertising and promotional
          communications by email, text message or postal mail, and to show you
          advertisements for products or services. This may include using your
          personal information to better tailor the Services and advertising on
          our Site and other websites.
        </p>
        <h5>Security and Fraud Prevention.</h5>{" "}
        <p>
          We use your personal information to detect, investigate or take action
          regarding possible fraudulent, illegal or malicious activity. If you
          choose to use the Services and register an account, you are
          responsible for keeping your account credentials safe. We highly
          recommend that you do not share your username, password, or other
          access details with anyone else. If you believe your account has been
          compromised, please contact us immediately.
        </p>
        <h5>Communicating with you.</h5>{" "}
        <p>
          We use your personal information to provide you with customer support
          and improve our Services. This is in our legitimate interests in order
          to be responsive to you, to provide effective services to you, and to
          maintain our business relationship with you.
        </p>
        <h4>Cookies</h4>
        <p>
          Like many websites, we use Cookies on our Site. For specific
          information about the Cookies that we use related to powering our
          store with Shopify, see https://www.shopify.com/legal/cookies. We use
          Cookies to power and improve our Site and our Services (including to
          remember your actions and preferences), to run analytics and better
          understand user interaction with the Services (in our legitimate
          interests to administer, improve and optimize the Services). We may
          also permit third parties and services providers to use Cookies on our
          Site to better tailor the services, products and advertising on our
          Site and other websites.
        </p>
        <p>
          Most browsers automatically accept Cookies by default, but you can
          choose to set your browser to remove or reject Cookies through your
          browser controls. Please keep in mind that removing or blocking
          Cookies can negatively impact your user experience and may cause some
          of the Services, including certain features and general functionality,
          to work incorrectly or no longer be available. Additionally, blocking
          Cookies may not completely prevent how we share information with third
          parties such as our advertising partners.
        </p>
        <h4>How We Disclose Personal Information</h4>
        <p>
          In certain circumstances, we may disclose your personal information to
          third parties for legitimate purposes subject to this Privacy Policy.
          Such circumstances may include:
        </p>
        <p>
          With vendors or other third parties who perform services on our behalf
          (e.g., IT management, payment processing, data analytics, customer
          support, cloud storage, fulfillment and shipping).
        </p>
        <p>
          With business and marketing partners, including Shopify, to provide
          services and advertise to you. [NOTE TO MERCHANT: INSERT THE FOLLOWING
          SENTENCE IF USING SHOPIFY’S AD SERVICES, SUCH AS SHOPIFY AUDIENCES]
          [For example, we use Shopify to support personalized advertising with
          third-party services]. Our business and marketing partners will use
          your information in accordance with their own privacy notices.
        </p>
        <p>
          When you direct, request us or otherwise consent to our disclosure of
          certain information to third parties, such as to ship you products or
          through your use of social media widgets or login integrations, with
          your consent.
        </p>
        <p>
          With our affiliates or otherwise within our corporate group, in our
          legitimate interests to run a successful business.
        </p>
        <p>
          In connection with a business transaction such as a merger or
          bankruptcy, to comply with any applicable legal obligations (including
          to respond to subpoenas, search warrants and similar requests), to
          enforce any applicable terms of service, and to protect or defend the
          Services, our rights, and the rights of our users or others.
        </p>
        <p>
          We have, in the past 12 months disclosed the following categories of
          personal information and sensitive personal information (denoted by *)
          about users for the purposes set out above in "How we Collect and Use
          your Personal Information" and "How we Disclose Personal Information":
        </p>
        <div className="table-container">
          <table className="data-table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Categories of Recipients</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>
                      Identifiers such as basic contact details and certain
                      order and account information
                    </li>
                    <li>
                      Commercial information such as order information, shopping
                      information and customer support information
                    </li>
                    <li>
                      Internet or other similar network activity, such as Usage
                      Data
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      Vendors and third parties who perform services on our
                      behalf (such as Internet service providers, payment
                      processors, fulfillment partners, customer support
                      partners, and data analytics providers)
                    </li>
                    <li>Business and marketing partners</li>
                    <li>Affiliates</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          We do not use or disclose sensitive personal information for the
          purposes of inferring characteristics about you.
        </p>
        <p>
          [NOTE TO MERCHANT- INSERT THE FOLLOWING PARAGRAPH AND CHART IF USING
          SHOPIFY’S AD SERVICES SUCH AS SHOPIFY AUDIENCES OR ENGAGING IN ANY
          OTHER ACTIVITY THAT MAY BE CONSIDERED “SELLING” OR “SHARING” PERSONAL
          INFORMATION OR PROCESSING PERSONAL INFORMATION FOR “TARGETED
          ADVERTISING”]
        </p>
        <p>
          We have “sold” and “shared” (as those terms are defined in applicable
          law) personal information over the preceding 12 months for the purpose
          of engaging in advertising and marketing activities, as follows.
        </p>
        <div className="table-container">
          <table className="data-table">
            <thead>
              <tr>
                <th>Category of Personal Information</th>
                <th>Categories of Recipients</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Identifiers such as basic contact details and certain order
                  and account information
                </td>
                <td>Business and marketing partners</td>
              </tr>
              <tr>
                <td>
                  Commercial information such as records of products or services
                  purchased and shopping information
                </td>
                <td>Business and marketing partners</td>
              </tr>
              <tr>
                <td>
                  Internet or other similar network activity, such as Usage Data
                </td>
                <td>Business and marketing partners</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>User Generated Content</h4>
        <p>
          The Services may enable you to post product reviews and other
          user-generated content. If you choose to submit user generated content
          to any public area of the Services, this content will be public and
          accessible by anyone.
        </p>
        <p>
          We do not control who will have access to the information that you
          choose to make available to others, and cannot ensure that parties who
          have access to such information will respect your privacy or keep it
          secure. We are not responsible for the privacy or security of any
          information that you make publicly available, or for the accuracy, use
          or misuse of any information that you disclose or receive from third
          parties.
        </p>
        <h4>Third Party Websites and Links</h4>
        <p>
          Our Site may provide links to websites or other online platforms
          operated by third parties. If you follow links to sites not affiliated
          or controlled by us, you should review their privacy and security
          policies and other terms and conditions. We do not guarantee and are
          not responsible for the privacy or security of such sites, including
          the accuracy, completeness, or reliability of information found on
          these sites. Information you provide on public or semi-public venues,
          including information you share on third-party social networking
          platforms may also be viewable by other users of the Services and/or
          users of those third-party platforms without limitation as to its use
          by us or by a third party. Our inclusion of such links does not, by
          itself, imply any endorsement of the content on such platforms or of
          their owners or operators, except as disclosed on the Services.
        </p>
        <h4>Children’s Data</h4>
        <p>
          The Services are not intended to be used by children, and we do not
          knowingly collect any personal information about children. If you are
          the parent or guardian of a child who has provided us with their
          personal information, you may contact us using the contact details set
          out below to request that it be deleted.
        </p>
        <p>
          As of the Effective Date of this Privacy Policy, we do not have actual
          knowledge that we “share” or “sell” (as those terms are defined in
          applicable law) personal information of individuals under 16 years of
          age.
        </p>
        <p>
          [NOTE TO MERCHANT: PLEASE CONSULT WITH LEGAL COUNSEL IF YOUR SITE IS
          CHILD FOCUSSED OR DIRECTED, AS MORE SPECIFIC DISCLOSURES MAY BE
          REQUIRED.]
        </p>
        <h4>Security and Retention of Your Information</h4>
        <p>
          Please be aware that no security measures are perfect or impenetrable,
          and we cannot guarantee “perfect security.” In addition, any
          information you send to us may not be secure while in transit. We
          recommend that you do not use unsecure channels to communicate
          sensitive or confidential information to us.
        </p>
        <p>
          How long we retain your personal information depends on different
          factors, such as whether we need the information to maintain your
          account, to provide the Services, comply with legal obligations,
          resolve disputes or enforce other applicable contracts and policies.
        </p>
        <h4>Your Rights and Choices</h4>
        <p>
          Depending on where you live, you may have some or all of the rights
          listed below in relation to your personal information. However, these
          rights are not absolute, may apply only in certain circumstances and,
          in certain cases, we may decline your request as permitted by law.
        </p>
        <h4>Right to Access / Know.</h4>
        <p>
          {" "}
          You may have a right to request access to personal information that we
          hold about you, including details relating to the ways in which we use
          and share your information.
        </p>
        <h4>Right to Delete. </h4>
        <p>
          You may have a right to request that we delete personal information we
          maintain about you.
        </p>
        <h4>Right to Correct. </h4>
        <p>
          You may have a right to request that we correct inaccurate personal
          information we maintain about you.
        </p>
        <h4>Right of Portability.</h4>
        <p>
          {" "}
          You may have a right to receive a copy of the personal information we
          hold about you and to request that we transfer it to a third party, in
          certain circumstances and with certain exceptions.
        </p>
        <p>
          [NOTE TO MERCHANT: IF USING SHOPIFY'S AD SERVICES SUCH AS SHOPIFY
          AUDIENCES OR ENGAGING IN ANY OTHER ACTIVITY THAT MAY BE CONSIDERED
          "SELLING" OR "SHARING" PERSONAL INFORMATION OR PROCESSING PERSONAL
          INFORMATION FOR "TARGETED ADVERTISING", INSERT THE PARAGRAPH BELOW AND
          ENSURE YOU PROVIDE CUSTOMERS THE ABILITY TO "OPT OUT" OF THIS ACTIVITY
          ON YOUR SITE BY USING SHOPIFY'S PRIVACY AND COMPLIANCE APP OR
          OTHERWISE.]
        </p>
        <h4>Right to Opt out of Sale or Sharing or Targeted Advertising. </h4>
        <p>
          You may have a right to direct us not to "sell" or "share" your
          personal information or to opt out of the processing of your personal
          information for purposes considered to be "targeted advertising", as
          defined in applicable privacy laws. Please note that if you visit our
          Site with the Global Privacy Control opt-out preference signal
          enabled, depending on where you are, we will automatically treat this
          as a request to opt-out of the "sale" or "sharing" of information for
          the device and browser that you use to visit the Site.
        </p>
        <p>
          [NOTE TO MERCHANT: IF YOU COLLECT INFORMATION THAT MAY BE DEEMED TO BE
          SENSITIVE PERSONAL INFORMATION UNDER APPLICABLE PRIVACY LAWS THERE MAY
          BE ADDITIONAL CONSENTS/DISCLOSURES REQUIRED. INSERT THE PARAGRAPH
          BELOW IF YOU COLLECT SENSITIVE PERSONAL INFORMATION AND CONSULT WITH
          EXTERNAL LEGAL COUNSEL TO CONFIRM YOUR RESPONSIBILITIES.]
        </p>
        <h4>
          Right to Limit and/or Opt out of Use and Disclosure of Sensitive
          Personal Information.
        </h4>{" "}
        <p>
          You may have a right to direct us to limit our use and/or disclosure
          of sensitive personal information to only what is necessary to perform
          the Services or provide the goods reasonably expected by an average
          individual.
        </p>
        <h4>Restriction of Processing: </h4>
        <p>
          You may have the right to ask us to stop or restrict our processing of
          personal information.
        </p>
        <h4>Withdrawal of Consent:</h4>
        <p>
          {" "}
          Where we rely on consent to process your personal information, you may
          have the right to withdraw this consent.
        </p>
        <h4>Appeal:</h4>{" "}
        <p>
          You may have a right to appeal our decision if we decline to process
          your request. You can do so by replying directly to our denial.
        </p>
        <h4>Managing Communication Preferences:</h4>
        <p>
          {" "}
          We may send you promotional emails, and you may opt out of receiving
          these at any time by using the unsubscribe option displayed in our
          emails to you. If you opt out, we may still send you non-promotional
          emails, such as those about your account or orders that you have made.
        </p>
        <p>
          You may exercise any of these rights where indicated on our Site or by
          contacting us using the contact details provided below.
        </p>
        <p>
          We will not discriminate against you for exercising any of these
          rights. We may need to collect information from you to verify your
          identity, such as your email address or account information, before
          providing a substantive response to the request. In accordance with
          applicable laws, You may designate an authorized agent to make
          requests on your behalf to exercise your rights. Before accepting such
          a request from an agent, we will require that the agent provide proof
          you have authorized them to act on your behalf, and we may need you to
          verify your identity directly with us. We will respond to your request
          in a timely manner as required under applicable law.
        </p>
        <p>
          [NOTE TO MERCHANT: INSERT THE FOLLOWING SENTENCE IF USING SHOPIFY'S AD
          SERVICES SUCH AS SHOPIFY AUDIENCES]
        </p>
        <p>
          We use Shopify's ad services such as Shopify Audiences to help
          personalize the advertising you see on third party websites. To
          restrict Shopify merchants that use these ad services from using your
          personal information for such services, visit
          https://privacy.shopify.com/en.
        </p>
        <h4>Complaints</h4>
        <p>
          If you have complaints about how we process your personal information,
          please contact us using the contact details provided below. If you are
          not satisfied with our response to your complaint, depending on where
          you live you may have the right to appeal our decision by contacting
          us using the contact details set out below, or lodge your complaint
          with your local data protection authority.
        </p>
        <h4>International Users</h4>
        <p>
          Please note that we may transfer, store and process your personal
          information outside the country you live in, including the United
          States. Your personal information is also processed by staff and third
          party service providers and partners in these countries.
        </p>
        <p>
          If we transfer your personal information out of Europe, we will rely
          on recognized transfer mechanisms like the European Commission's
          Standard Contractual Clauses, or any equivalent contracts issued by
          the relevant competent authority of the UK, as relevant, unless the
          data transfer is to a country that has been determined to provide an
          adequate level of protection.
        </p>
        <h4>Contact</h4>
        <p>
          Should you have any questions about our privacy practices or this
          Privacy Policy, or if you would like to exercise any of the rights
          available to you, please call [TOLL FREE TELEPHONE NUMBER IF YOU HAVE
          A PHYSICAL RETAIL LOCATION] or email us at support@overbar.in or
          contact us at 1103, Marina Building NL Road Bandra West, Near Lucky
          Restaurant, 400050 Mumbai MH, India.
        </p>
        <p>
          [NOTE TO MERCHANT: INSERT THE FOLLOWING IF YOUR SITE IS GOVERNED BY
          GDPR] For the purpose of applicable data protection laws, we are the
          data controller of your personal information. Our representative in
          the [EEA] [and] [the UK] is [INSERT REPRESENTATIVE DETAILS].
        </p>
        <p>Stay up to date with our latest collections and exclusive offers.</p>
      </div>
    </div>
  );
};

export default StorePolicy;
