import React, { useState,useEffect} from "react";
import "../Styles/Home.css";
import { Navbar } from "../Core/Navbar";
import { Link } from "react-router-dom";
import { getBanner } from "../Services/Operations/ProductAPI";

function Home() {

  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const banner = async () => {
      try {
        const response = await getBanner();
        console.log("Banner : ", response);
        if (response.success === true) {
          setBanner(response.banner_list[0].banner_image_path)
        }
      } catch (error) {
        console.error("Error get profile:", error);
      }
    };
    banner();
  }, []);

  return (
    <div>
      <Navbar
        src1={require("../../Assets/Img/Vector.png")}
        src2={require("../../Assets/Img/material-symbols-light_search.png")}
        src3={require("../../Assets/Img/solar_cart-outline.png")}
      />
      <div className="home-container">
        <section className="header-banner">
          <img
            src={banner}
            alt="Banner"
            className="header-image"
            style={{ height: "100vh" }}
          />
          <div className="overlay-text">
            <p
              style={{
                fontFamily: "'Helvetica Neue', sans-serif",
                fontWeight: "700",
                fontStyle: "italic",
                fontSize: "21px",
              }}
            >
              ob24
            </p>
            <button
              style={{
                fontFamily: "Arboria",
                FontWeight: "900",
                fontSize: "17px",
                width: "114px",
                height: "41px",
                backgroundColor: "transparent",
                padding: "0px",
                border: "none",
                outline: "none",
                marginTop: "0px",
              }}
            >
              SHOP NOW
            </button>
          </div>
        </section>

        <section className="find-us-section">
          <h2
            style={{
              textAlign: "left",
              fontFamily: "Arboria",
              color: "#252338",
              fontWeight: "500",
              marginTop: "51px",
            }}
          >
            FIND US!
          </h2>
          <div className="find-us-images">
            <div className="image-placeholder">
              <img
                src={require("../../Assets/Img/Item.png")}
                alt="Banner"
                className="header-image"
                style={{ height: "300px" }}
              />
            </div>
            <div className="image-placeholder">
              <img
                src={require("../../Assets/Img/Item.png")}
                alt="Banner"
                className="header-image"
                style={{ height: "300px" }}
              />
            </div>
          </div>
        </section>

        <footer className="store-policy">
          <Link to="/store-policy"><h3>STORE POLICY</h3></Link>
          <ul>
            <Link to="/term-services"><li>TERMS OF SERVICE</li></Link>
            <Link to="/shipping-policy"><li>SHIPPING AND EXCHANGE</li></Link>
            <Link to="/Track-order"><li>TRACK YOUR ORDER</li></Link>
          </ul>
        </footer>
      </div>
      <h3
        style={{
          fontFamily: "Arboria",
          color: "#DEBBA2",
          width: "100%",
          textAlign: "center",
          marginTop: "73px",
          marginBottom: "38px",
        }}
      >
        (c) 2024 overbar, all rights resered
      </h3>
    </div>
  );
}

export default Home;
