import React from "react";
import "../Styles/shippingPolicy.css";
import { Navbar } from "../Core/Navbar";

const ShippingPolicy = () => {
  return (
    <div>
      <Navbar
        color="black"
        src1={require("../../Assets/Img/Vector(1).png")}
        src2={require("../../Assets/Img/material-symbols-light_search(1).png")}
        src3={require("../../Assets/Img/solar_cart-outline(1).png")}
      />
      <div className="shipping-policy-container">
        <h4 style={{textTransform: "capitalize"}}> Shipping policy</h4>
        <p>
          Orders are shipped within 3-5 days or as per the delivery date agreed
          at the time of order confirmation and delivering of the shipment
          subject to Courier Company / post office norms. Delivery of all orders
          will be to the address provided by the buyer. Delivery of our services
          will be confirmed on your mail ID as specified during registration.
        </p>

        <p>
          For International buyers, orders are shipped and delivered through
          registered international courier companies and/or International speed
          post only. For domestic buyers, orders are shipped through registered
          domestic courier companies and /or speed post only. Overbar Clothing
          is not liable for any delay in delivery by the courier company /
          postal authorities and only guarantees to hand over the consignment to
          the courier company or postal authorities within 3-5 days from the
          date of the order and payment or as per the delivery date agreed at
          the time of order confirmation. For any issues in utilising our
          services you may contact our helpdesk – support@overbar.in
        </p>

        <p>Stay up to date with our latest collections and exclusive offers.</p>
      </div>
    </div>
  );
};

export default ShippingPolicy;
