import React, { useEffect, useState } from "react";
import "../Styles/OrderHistory.css";
import { useLocation, useNavigate } from "react-router";
import {
  cancelOrder,
  getSingleOrderDetails,
} from "../Services/Operations/ProductAPI";
import { Navbar } from "../Core/Navbar";
import "../Styles/Modal.css";
import "../Styles/Login.css";
import { useSelector } from "react-redux";

// Custom Modal Component
function CustomModal({
  isOpen,
  onClose,
  onConfirm,
  upiId,
  setUpiId,
  bankDetails,
  setBankDetails,
  paymentMethod,
  setPaymentMethod,
}) {
  if (!isOpen) return null;
  return (
    <div className="modal-overlay login-form" style={{ maxWidth: "none" }}>
      <div className="modal-content login-phone">
        <h2>Are you sure you want to cancel this order?</h2>
        <div>
          <label>
            <input
              type="radio"
              value="bank"
              checked={paymentMethod === "bank"}
              onChange={() => setPaymentMethod("bank")}
            />
            Bank Details
          </label>

          <label style={{ marginLeft: "20px" }}>
            <input
              type="radio"
              value="upi"
              checked={paymentMethod === "upi"}
              onChange={() => setPaymentMethod("upi")}
            />
            UPI ID
          </label>
        </div>
        {paymentMethod === "bank" && (
          <div>
            <h4>Bank Details</h4>
            <input
              className="login-input"
              type="text"
              placeholder="Holder Name"
              value={bankDetails.holderName}
              onChange={(e) =>
                setBankDetails({ ...bankDetails, holderName: e.target.value })
              }
              style={{ width: "100%" }}
            />
            <input
              className="login-input"
              type="text"
              placeholder="IFSC Code"
              value={bankDetails.ifscCode}
              onChange={(e) =>
                setBankDetails({ ...bankDetails, ifscCode: e.target.value })
              }
              style={{ width: "100%" }}
            />
            <input
              type="text"
              placeholder="Account Number"
              value={bankDetails.accNo}
              onChange={(e) =>
                setBankDetails({ ...bankDetails, accNo: e.target.value })
              }
              style={{ width: "100%" }}
            />
            <input
              type="text"
              placeholder="Confirm Account Number"
              value={bankDetails.confirmAccNo}
              onChange={(e) =>
                setBankDetails({ ...bankDetails, confirmAccNo: e.target.value })
              }
              style={{ width: "100%" }}
            />
            <input
              type="text"
              placeholder="Bank Name"
              value={bankDetails.bankName}
              onChange={(e) =>
                setBankDetails({ ...bankDetails, bankName: e.target.value })
              }
              style={{ width: "100%" }}
            />
          </div>
        )}

        {paymentMethod === "upi" && (
          <div>
            <h4>UPI ID</h4>
            <input
              type="text"
              placeholder="Enter UPI ID"
              value={upiId}
              onChange={(e) => setUpiId(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>
        )}

        <div className="modal-actions">
          <button
            onClick={onClose}
            style={{
              padding: "5px 16px",
              backgroundColor: "red",
              border: "none",
              color: "white",
              borderRadius: "5px",
            }}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            style={{
              padding: "5px 16px",
              backgroundColor: "green",
              border: "none",
              color: "white",
              borderRadius: "5px",
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

function OrderDetails() {
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelProductId, setCancelProductId] = useState(null);
  const [upiId, setUpiId] = useState("");
  const [bankDetails, setBankDetails] = useState({
    holderName: "",
    ifscCode: "",
    accNo: "",
    confirmAccNo: "",
    bankName: "",
  });
  const { token } = useSelector((state) => state.user);

  const [paymentMethod, setPaymentMethod] = useState("bank");

  const navigate = useNavigate();

  useEffect(() => {
    const userOrder = async () => {
      try {
        const response = await getSingleOrderDetails(location.state?.id);
        console.log("order data", response);
        setOrderDetails(response.order_details);
        calculateTotals(response.order_details);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };
    userOrder();
  }, [location.state?.id]);

  const calculateTotals = (orderDetails) => {
    let total = 0;
    let quantity = 0;

    orderDetails.forEach((product) => {
      total += product.price * product.quantity;
      quantity += product.quantity;
    });

    setTotalPrice(total);
    setTotalQuantity(quantity);
  };

  const handleCancel = (productId) => {
    console.log("...........ahfjahf");
    setIsModalOpen(true);
    setCancelProductId(productId);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCancelProductId(null);
    setUpiId("");
    setBankDetails({
      holderName: "",
      ifscCode: "",
      accNo: "",
      confirmAccNo: "",
      bankName: "",
    });
  };

  const handleConfirmCancel = async () => {
    try {
      let response;
      if (paymentMethod === "upi") {
        const upiPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+$/;
        if (!upiPattern.test(upiId)) {
          alert("Invalid UPI ID. Please enter a valid UPI ID.");
          return;
        }
        response = await cancelOrder({
          access_token: token,
          order_details_id: cancelProductId,
          bank_details: paymentMethod,
          upi_id: upiId,
        });
      } else if (paymentMethod === "bank") {
        const { holderName, ifscCode, accNo, confirmAccNo, bankName } =
          bankDetails;
        if (!holderName || !ifscCode || !accNo || !confirmAccNo || !bankName) {
          alert("All bank details fields must be filled.");
          return;
        }
        if (accNo !== confirmAccNo) {
          alert("Account numbers do not match.");
          return;
        }
        response = await cancelOrder({
          access_token: token,
          order_details_id: cancelProductId,
          bank_details: paymentMethod,
          ac_holder_name: holderName,
          ifsc_code: ifscCode,
          ac_no: accNo,
          bank_name: bankName,
          confirm_ac_no: confirmAccNo,
        });
      }

      if (response.success === true) {
        navigate("/profile", { state: { from: "/checkout" } });
      }
    } catch (error) {
      console.error("Error canceling order:", error);
    } finally {
      handleModalClose();
    }
  };

  return (
    <>
      <Navbar
        color="black"
        src1={require("../../Assets/Img/Vector(1).png")}
        src2={require("../../Assets/Img/material-symbols-light_search(1).png")}
        src3={require("../../Assets/Img/solar_cart-outline(1).png")}
      />
      <div className="order-details-container">
        <h2
          style={{ fontWeight: "bold", fontSize: "33px", paddingTop: "48px" }}
        >
          Order Details
        </h2>

        {orderDetails.length > 0 && (
          <div className="order-info">
            <div className="order-section">
              <h4 style={{ fontWeight: "bold", fontSize: "26px" }}>
                Order Information
              </h4>
              <p>
                <strong>Customer:</strong> {orderDetails[0].first_name}{" "}
                {orderDetails[0].last_name}
              </p>
              <p>
                <strong>Contact:</strong> {orderDetails[0].contact}
              </p>
              <p>
                <strong>Address:</strong> {orderDetails[0].address},{" "}
                {orderDetails[0].city}, {orderDetails[0].state_id} -{" "}
                {orderDetails[0].pin_code}, {orderDetails[0].country}
              </p>
              <p>
                <strong>Order ID:</strong> {orderDetails[0].bill_id}
              </p>
              <p>
                <strong>Order Date:</strong> {orderDetails[0].created_date}{" "}
                {orderDetails[0].created_time}
              </p>
              <p>
                <strong>Status:</strong> {orderDetails[0].payment_status}
              </p>
            </div>
          </div>
        )}

        <div className="order-section">
          <h4
            style={{ fontWeight: "bold", fontSize: "26px", marginTop: "20px" }}
          >
            Product Details
          </h4>
          <div style={{ overflowX: "auto" }}>
            <table className="product-table">
              <thead>
                <tr>
                  <th>Product Image</th>
                  <th>Product Name</th>
                  <th>Size</th>
                  <th>Quantity</th>
                  <th>Price per Item</th>
                  <th>Total Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.map((product) => (
                  <tr key={product.id}>
                    <td>
                      <img
                        src={product.product_banner_path}
                        alt={product.product_name}
                        className="product-image"
                        style={{width:"50px"}}
                      />
                    </td>
                    <td>{product.product_name}</td>
                    <td>{product.size}</td>
                    <td>{product.quantity}</td>
                    <td>₹{product.price}</td>
                    <td>₹{(product.price * product.quantity).toFixed(2)}</td>
                    <td>
                      <button
                        onClick={() => handleCancel(product.id)}
                        style={{
                          backgroundColor: product.cancel_button ? "red" : "",
                          color: "white",
                          border: "none",
                          outline: "none",
                          padding: "5px 10px",
                        }}
                      >
                        {product.cancel_button
                          ? "cancel"
                          : ""}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="order-section">
          <h4 style={{ fontWeight: "bold", fontSize: "26px" }}>
            Payment Summary
          </h4>
          <p>
            <strong>Grand Total:</strong> ₹{totalPrice.toFixed(2)}
          </p>
        </div>

        <CustomModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onConfirm={handleConfirmCancel}
          upiId={upiId}
          setUpiId={setUpiId}
          bankDetails={bankDetails}
          setBankDetails={setBankDetails}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />
      </div>
    </>
  );
}

export default OrderDetails;
