import React, { useState } from "react";
import OtpInput from "react-otp-input";
import "../Styles/Otp.css";
import { otpVerify } from "../Services/Operations/ProductAPI";
import { useLocation, useNavigate } from "react-router";

const Otp = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async () => {
    try {
      const result = await otpVerify(otp);
      //console.log(result);
      if (result.success === true) {
        //console.log("state path : ",location.state?.from)
        const redirectTo = location.state?.from || "/";
        navigate(redirectTo);
      }
    } catch (error) {
      //console.log("ERROR MESSAGE for signup - ", error);
    }
  };

  return (
    <div className="otp-wrapper">
      <div className="otp-container">
        <h2 className="otp-header">Verify OTP</h2>
        <p className="otp-description">
          Please enter the 6-digit OTP sent to your phone.
        </p>

        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            width: "35px",
            height: "35px",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            border: "2px solid #ccc",
            textAlign: "center",
            outline: "none",
          }}
          containerStyle={{
            justifyContent: "space-between",
          }}
        />

        <button
          className="otp-submit-btn"
          onClick={handleSubmit}
          disabled={otp.length !== 6}
        >
          Verify OTP
        </button>
      </div>
    </div>
  );
};

export default Otp;
