import { createSlice } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

const initialState = {
    items:  Cookies.get('items') ? JSON.parse(Cookies.get('items')) : [],
    totalItems: Cookies.get('totalItem') ? Cookies.get('totalItem') : 0,
    cartItems: Cookies.get('cartItems') ? JSON.parse(Cookies.get('cartItems')) : [],
    productItems: Cookies.get('productItems') ? JSON.parse(Cookies.get('productItems')) : {},
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addCart: (state, action) => {
            //console.log("Adding item to cart with payload:", action.payload);
            
            const existingItem = state.items.find(item => item.id === action.payload.id);
          
            if (existingItem) {
              // Update the existing item's properties if it already exists
              existingItem.quantity = action.payload.quantity; 
              existingItem.size = action.payload.size;
              existingItem.size_id = Number(action.payload.size_id);  // Convert size_id to number
            } else {
              // Add the new item to the cart, including size_id as a number
              state.items.push({ 
                ...action.payload, 
                size_id: Number(action.payload.size_id),  // Convert size_id to number
                quantity: action.payload.quantity || 1,
                price: Number(action.payload.price) || 0  
              });
            }
          
            // Recalculate total items and total amount
            state.totalItems = state.items.length;
            state.totalAmount = state.items.reduce(
              (total, item) => total + (Number(item.price) || 0) * (Number(item.quantity) || 0), 
              0
            );
            //console.log("Total Amount: ", state.totalAmount);
          
            // Store updated cart in cookies
            Cookies.set('items', JSON.stringify(state.items));
            Cookies.set('totalItem', state.totalItems);
          },
          
        
        
        removeCart: (state, action) => {
            state.items = state.items.filter(item => item.id !== action.payload);
            state.totalItems = state.items.length;
            state.totalAmount = state.items.reduce((total, item) => total + Number(item.price) * Number(item.quantity), 0);
            Cookies.set('totalItem', state.totalItems);
        },
        incrementQuantity: (state, action) => {
            const item = state.items.find(item => item.id === action.payload);
            if (item) {
                item.quantity = Number(item.quantity) + 1; // Ensure quantity is a number
            }
            state.totalItems = state.items.length;
            state.totalAmount = state.items.reduce((total, item) => total + Number(item.price) * Number(item.quantity), 0);
            Cookies.set('totalItem', state.totalItems);
        },
        decrementQuantity: (state, action) => {
            const item = state.items.find(item => item.id === action.payload);
            if (item && item.quantity > 1) {
                item.quantity = Number(item.quantity) - 1; // Ensure quantity is a number
            } else {
                state.items = state.items.filter(item => item.id !== action.payload);
            }
            state.totalItems = state.items.length;
            state.totalAmount = state.items.reduce((total, item) => total + Number(item.price) * Number(item.quantity), 0);
            Cookies.set('totalItem', state.totalItems);
        },
        setCheckoutCartItems: (state, action) => {
          console.log("Cart Item : ",action.payload)
          if (state.productItems && Object.keys(state.productItems).length > 0) {
              state.productItems = {}; 
          }
          state.cartItems = action.payload;
          Cookies.set('cartItems', JSON.stringify(state.cartItems));
          Cookies.set('productItems', JSON.stringify(state.productItems));
      },
      
      setCheckoutProductItems: (state, action) => {
        console.log("Product Item : ",action.payload)
          if (state.cartItems && state.cartItems.length > 0) {
              state.cartItems = [];
          }
          state.productItems = action.payload;
          Cookies.set('productItems', JSON.stringify(state.productItems));
          Cookies.set('cartItems', JSON.stringify(state.cartItems));
      },
      clearCart: (state) => {
        state.cartItems = [];
        state.productItems = {};
        Cookies.remove('cartItems');
        Cookies.remove('productItems');
    },
    },
});

export const { addCart, removeCart, incrementQuantity, decrementQuantity,setCheckoutCartItems, setCheckoutProductItems } = cartSlice.actions;
export default cartSlice.reducer;
