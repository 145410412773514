import React from "react";
import "../Styles/SizeChartModal.css";

const SizeChartModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <h2 style={{ fontFamily: "Arboria" }}>Size Chart</h2>
        <table className="size-chart-table">
          <thead>
            <tr>
              <th>Size</th>
              <th>Bust (in inches)</th>
              <th>Waist (in inches)</th>
              <th>Hips (in inches)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>XS</td>
              <td>32-34</td>
              <td>24-26</td>
              <td>34-36</td>
            </tr>
            <tr>
              <td>S</td>
              <td>34-36</td>
              <td>26-28</td>
              <td>36-38</td>
            </tr>
            <tr>
              <td>M</td>
              <td>36-38</td>
              <td>28-30</td>
              <td>38-40</td>
            </tr>
            <tr>
              <td>L</td>
              <td>38-40</td>
              <td>30-32</td>
              <td>40-42</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SizeChartModal;