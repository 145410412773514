import React, { useEffect, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import "../Styles/Login.css";
import "../Styles/Profile.css";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Navbar } from "../Core/Navbar";
import {
  getLogout,
  getProfile,
  profileUpdate,
} from "../Services/Operations/ProductAPI";
import { logout } from "../Redux/user_information";
import { useDispatch, useSelector } from "react-redux";
import Register from "./Register";
import "../Styles/OrderHistory.css";
import { setBankDetailsData } from "../Redux/user_information";

const Profile = () => {
  const { token } = useSelector((state) => state.user);
  const { signupData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const [signupData, setSignupData] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [logoutProfile, setLogout] = useState(false);
  const [profile, setProfile] = useState(true);
  const [profileData, setProfileData] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [order, setOrder] = useState(false);
  const [bank, setBank] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleProfile = () => {
    setProfile(true);
    setLogout(false);
    setOrder(false);
    setBank(false);
  };

  const handleOrder = () => {
    setProfile(false);
    setLogout(false);
    setOrder(true);
    setBank(false);
  };

  const handleBank = () => {
    setBank(true);
    setProfile(false);
    setLogout(false);
    setOrder(false);
  };

  const handleLogOut = () => {
    setProfile(false);
    setLogout(true);
    setOrder(false);
    setBank(false);
  };

  const handleLogOutfromLogin = async () => {
    try {
      const response = await getLogout({ access_token: token });
      if (response.success === true) {
        toast.error("User logged out successfully");
        dispatch(logout());
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleProfileUpdate = async (data) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("access_token", token);
      formData.append("full_name", data.name);
      formData.append("dob", formatDateToYYYYMMDD(data.dob));
      formData.append("email", data.email);
      formData.append("address", data.location);
      if (data.profile && data.profile[0]) {
        formData.append("profile_pic", data.profile[0]);
      }
      for (let [key, value] of formData.entries()) {
        //console.log(`${key}: ${value}`);
      }
      const response = await profileUpdate(formData);
      //console.log("profile update : ", response);
      if (response.success === true) {
        try {
          const response = await getProfile(token);
          console.log("profile : ", response.bank_details);
          if (response.success === true) {
            setProfileData(response.user_details);
            setProfileImage(response.user_details.profile_pic_path);
            setBankDetails(response.bank_details);
            dispatch(setBankDetailsData(response.bank_details));
            console.log("bank details : ", response.bank_details);
          }
        } catch (error) {
          console.error("Error fetching profile data:", error);
        }
      }
    } catch (error) {
      toast.error("Profile update error");
      console.error("Error updating profile:", error);
    }
    setIsLoading(false);
  };

  const onSubmit = (data) => {
    handleProfileUpdate(data);
  };

  useEffect(() => {
    if (profileData.dob) {
      setFormattedDOB(formatDateToDDMMYYYY(profileData.dob));
    }
  }, [profileData.dob]);

  const formatDateToDDMMYYYY = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatDateToYYYYMMDD = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const handleView = (id) => {
    //console.log("id : ", id);
    navigate("/order-details", {
      state: { id: id },
    });
  };

  useEffect(() => {
    if (location.state && location.state.showOrder) {
      setProfile(false);
      setOrder(true);
    }
  }, [location.state]);

  const [formattedDOB, setFormattedDOB] = useState("");

  useEffect(() => {
    const userProfile = async () => {
      try {
        const response = await getProfile(token);
        console.log("profile data : ", response);
        if (response.success === true) {
          setProfileData(response.user_details);
          setProfileImage(response.user_details.profile_pic_path);
          //console.log("dob : ",response.user_details.dob)
          setOrderDetails(response.order_details);
          setBankDetails(response.bank_details);
          dispatch(setBankDetailsData(response.bank_details));
          console.log("bank details : ", response.bank_details);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    userProfile();
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/register", { replace: true });
    }
  }, [token, navigate]);

  useEffect(() => {
    if (profileData) {
      setValue("name", profileData.full_name);
      setValue("email", profileData.email);
      setValue("dob", formattedDOB1);
      setValue("location", profileData.address);
    }
  }, [profileData, setValue]);

  const formattedDOB1 = profileData.dob
    ? new Date(profileData.dob).toISOString().split("T")[0]
    : "";

  return (
    <div className="profile-wrapper">
      {signupData ? (
        <div>
          <div className="profile-navbar">
            <Navbar
              color="black"
              src1={require("../../Assets/Img/Vector(1).png")}
              src2={require("../../Assets/Img/material-symbols-light_search(1).png")}
              src3={require("../../Assets/Img/solar_cart-outline(1).png")}
            />
          </div>
          <div className="profile-container">
            <div className="profile-name">
              <div className="profile-user">
                {!profileImage ? (
                  <FaUserAlt className="profile-icon-user" />
                ) : (
                  <img
                    src={profileData.profile_pic_path}
                    alt="..."
                    width="130"
                    className="profile-icon-user"
                  />
                )}
                <h2>{profileData.full_name}</h2>
              </div>
              <div className="profile-btn">
                <button type="button" onClick={handleProfile}>
                  My Profile
                </button>
                <button type="button" onClick={handleOrder}>
                  Order History
                </button>
                <button type="button" onClick={handleBank}>
                  Bank Details
                </button>
                <button type="button" onClick={handleLogOut}>
                  Log Out
                </button>
              </div>
            </div>
            <div
              className="login-form login-form1 profile-login"
              style={{
                padding: "0px",
                overflowX: "auto",
                width: "100%",
                display: "block",
                maxWidth: "none",
              }}
            >
              {profile ? (
                <form
                  className="form"
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ padding: "15px", maxWidth: "none" }}
                >
                  <div className="login-phone">
                    <label htmlFor="name">Enter Your Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      // defaultValue={profileData.full_name}
                      placeholder="Enter Your Number"
                      className="login-input"
                      {...register("name", { required: "name is required" })}
                    />
                    {errors.name && <p>{errors.name.message}</p>}
                  </div>
                  <div className="login-phone">
                    <label htmlFor="email">Enter Your Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      // defaultValue={profileData.email}
                      placeholder="Enter Your Email"
                      className="login-input"
                      {...register("email", { required: "email is required" })}
                    />
                    {errors.email && <p>{errors.email.message}</p>}
                  </div>
                  <div className="login-phone">
                    <label htmlFor="phone">Enter Your Phone Number</label>
                    <input
                      type="number"
                      id="phone"
                      name="phone"
                      defaultValue={profileData.mobile_no}
                      placeholder="Enter The Phone Number"
                      className="login-input"
                      disabled
                    />
                  </div>
                  <div className="login-phone">
                    <label htmlFor="dob">Enter Your DOB</label>
                    <input
                      type="date"
                      id="dob"
                      name="dob"
                      // defaultValue={formattedDOB1}
                      placeholder="Enter Your DOB"
                      className="login-input"
                      onChange={(e) => setFormattedDOB(e.target.value)}
                      {...register("dob", { required: "DOB is required" })}
                    />
                    {errors.dob && <p>{errors.dob.message}</p>}
                  </div>
                  <div className="login-phone">
                    <label htmlFor="location">Enter Your Location</label>
                    <input
                      type="text"
                      id="location"
                      name="location"
                      // defaultValue={profileData.address}
                      placeholder="Enter Your Location"
                      className="login-input"
                      {...register("location")}
                    />
                    {errors.location && <p>{errors.location.message}</p>}
                  </div>
                  <div className="login-phone">
                    <label htmlFor="profile">Profile Picture</label>
                    <input
                      type="file"
                      id="profile"
                      name="profile"
                      {...register("profile")}
                    />
                    {errors.profile && <p>{errors.profile.message}</p>}
                  </div>
                  <button type="submit" className="form-btn">
                    {isLoading ? "Processing..." : "Save"}
                  </button>
                </form>
              ) : order ? (
                orderDetails.length > 0 ? (
                  <table className="product-table" style={{ padding: "15px" }}>
                    <thead>
                      <tr>
                        <th>SL NO</th>
                        <th>BILL ID</th>
                        <th>MRP</th>
                        <th>DATE</th>
                        <th>STATUS</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails.map((product, index) => (
                        <tr key={product.id}>
                          <td>{index + 1}</td>
                          <td>{product.bill_id}</td>
                          <td>{product.subtotal}</td>
                          <td>{product.created_date}</td>
                          <td>
                            <span
                              className="status"
                              style={{
                                backgroundColor: product.payment_status
                                  ? "#6a5acd"
                                  : "",
                              }}
                            >
                              {product.payment_status}
                            </span>
                          </td>
                          <td>
                            <button
                              className="view-btn"
                              onClick={() => handleView(product.id)}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>
                    <p style={{ textAlign: "center" }}>No orders yet</p>
                    <p style={{ textAlign: "center" }}>
                      Go to store to place an order.
                    </p>
                  </div>
                )
              ) : logoutProfile ? (
                <div className="logout-section" style={{ padding: "15px" }}>
                  <h3>Do you want to log out?</h3>
                  <button onClick={handleLogOutfromLogin}>Log Out</button>
                </div>
              ) : (
                <div className="bank-details-container">
                  {bankDetails && bankDetails.length > 0 ? (
                    <div className="bank-details">
                      <h2 style={{ fontWeight: "bold" }}>Bank Details</h2>
                      <div style={{ padding: " 0px 32px" }}>
                        <p>
                          <strong>Account Holder Name:</strong>{" "}
                          {bankDetails[0].ac_holder_name || "N/A"}
                        </p>
                        <p>
                          <strong>Account Number:</strong>{" "}
                          {bankDetails[0].ac_no || "N/A"}
                        </p>
                        <p>
                          <strong>Bank Name:</strong>{" "}
                          {bankDetails[0].bank_name || "N/A"}
                        </p>
                        <p>
                          <strong>IFSC Code:</strong>{" "}
                          {bankDetails[0].ifsc_code || "N/A"}
                        </p>
                        <p>
                          <strong>UPI ID:</strong>{" "}
                          {bankDetails[0].upi_id || "N/A"}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="no-bank-details">
                      <h2 style={{ margin: "49px 0px" }}>
                        No Bank Details Added
                      </h2>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Register />
      )}
    </div>
  );
};

export default Profile;
