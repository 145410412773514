import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Navbar } from "../Core/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { addCart } from "../Redux/Cart_System";
import { FaAngleUp, FaAngleDown } from "react-icons/fa6";
import "../Styles/ProductDetails.css";
import SizeChartModal from "../Pages/SizeChartModel";
import { getProductDetails } from "../Services/Operations/ProductAPI";
import { toast } from 'react-toastify';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductDetails = () => {
  const [showDescription, setShowDescription] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedSizeId, setSelectedSizeId] = useState(null);
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productDetails, setProductDetails] = useState([]);
  const [productSize, setProductSize] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [sliderWidth, setSliderWidth] = useState("100%"); // Default slider width

  const location = useLocation();
  // const product = location.state?.product;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [productId, setProductId] = useState(
    new URLSearchParams(location.search).get("product_url")
  );

  const cartItems = useSelector((state) => state.cart.items);
  const cartItem = cartItems.find((item) => item.id === productId);

  const handleBuyNow = () => {
    if (!selectedSize) {
      setError(true);
      return;
    }
  
    setError(false);
  
    const subtotal = productDetails[0]?.mrp * quantity;
  
    const updatedProductDetails = {
      ...productDetails[0],  
      size: selectedSize,    
      quantity: quantity,    
      subtotal: subtotal,  
      size_id:selectedSizeId
    };
    navigate("/checkout", {
      state: {
        product_details: updatedProductDetails,
      },
    });
  };
  

  useEffect(() => {
    setProductId(new URLSearchParams(location.search).get("product_url"));
  }, [location.search]);

  useEffect(() => {
    if (cartItem) {
      setQuantity(cartItem.quantity);
      setSelectedSize(cartItem.size);
    } else {
      setQuantity(1);
      setSelectedSize(null);
    }
  }, [cartItem]);

  useEffect(() => {
    const productDetails = async () => {
      try {
        console.log("product : ", productId);
        const response = await getProductDetails(productId);
        console.log("cat data", response);
        if (response.success === true) {
          console.log("......", response.product_details);
          // console.log(".........", response.product_details[0].multi_size);
          setProductDetails(response.product_details);
          console.log("Product details : ", response.product_details);
          setProductSize(response.product_details[0].multi_size);
          setProductImage(response.product_details[0].multi_image);
          // console.log("size : ",response.product_details[0].multi_size)
        }
      } catch (error) {
        console.error("Error get profile:", error);
      }
    };
    productDetails();
  }, []);

  useEffect(() => {
    const updateSliderWidth = () => {
      const newWidth = window.innerWidth > 700 ? "48%" : "100%";
      setSliderWidth(newWidth);
    };
    updateSliderWidth();
    window.addEventListener("resize", updateSliderWidth);
    return () => {
      window.removeEventListener("resize", updateSliderWidth);
    };
  }, []);

  const handleAddToCart = () => {
    if (!selectedSize) {
      setError(true);
      return;
    }
    setError(false);
    if (productDetails) {
      dispatch(
        addCart({
          ...productDetails[0],
          quantity,
          size: selectedSize,
          size_id: selectedSizeId,
        })
      );
      toast.success("Item is added to cart");
      navigate("/AllProduct");
    }
  };

  const handleIncrement = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    //  console.log("Adding to cart with size:", selectedSize);
    // if (productDetails) {
    //   dispatch(
    //     addCart({ ...productDetails[0], quantity: newQuantity, size: selectedSize,size_id:selectedSizeId})
    //   );
    // }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      // if (productDetails) {
      //   dispatch(
      //     addCart({ ...productDetails[0], quantity: newQuantity, size: selectedSize,size_id:selectedSizeId})
      //   );
      // }
    }
  };

  const totalPrice = cartItem
    ? cartItem.mrp * cartItem.quantity
    : productDetails[0]?.mrp * quantity;

  const openSizeChart = () => {
    setIsModalOpen(true);
  };

  const closeSizeChart = () => {
    setIsModalOpen(false);
  };

  const handleSizeSelection = (size, size_id) => {
    console.log("size in product details : ", size_id);
    setSelectedSize(size);
    setSelectedSizeId(size_id);
    setError(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <div>
      <Navbar
        color="black"
        src1={require("../../Assets/Img/Vector(1).png")}
        src2={require("../../Assets/Img/material-symbols-light_search(1).png")}
        src3={require("../../Assets/Img/solar_cart-outline(1).png")}
      />
      <div style={{ flexDirection: "column", gap: "0px" }}>
        <nav
          className="breadcrumb"
          style={{
            marginBottom: "0px",
            fontFamily: "Arboria",
            display: "flex",
            gap: "6px",
          }}
        >
          <span>HOME</span> &gt; <span>SHOP ALL</span> &gt;{" "}
          <span>{productDetails?.product_name}</span>
        </nav>

        <div className="product-section">
          <Slider {...settings} style={{ width: sliderWidth }}>
            {productImage.map((image, index) => (
              <div key={index}>
                <img
                  src={`${"https://sianceedu.com/overbar/uploads/product_multi_image"}/${
                    image.image
                  }`}
                  alt={image.alt}
                  className="product-image"
                />
              </div>
            ))}
          </Slider>
          <div className="product-info">
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h1 className="product-name" style={{ fontFamily: "Arboria" }}>
                  {productDetails[0]?.product_name}
                </h1>
                <p className="product-price" style={{ fontFamily: "Arboria" }}>
                  ₹ {totalPrice}
                </p>
              </div>
              <div className="quantity-selector">
                <button onClick={handleDecrement} disabled={quantity <= 1}>
                  -
                </button>
                <span>{quantity}</span>
                <button onClick={handleIncrement}>+</button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                className="size-options"
                style={{ marginBottom: "17px", fontFamily: "Arboria" }}
              >
                <p style={{ fontFamily: "Arboria" }}>Select Your Size</p>
                {productSize.map((sizeObj) => (
                  <button
                    key={sizeObj.size_id}
                    className={`size-btn ${
                      selectedSize === sizeObj.size ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleSizeSelection(sizeObj.size, sizeObj.size_id)
                    }
                    style={{
                      backgroundColor:
                        selectedSize === sizeObj.size ? "orange" : "white",
                      color: selectedSize === sizeObj.size ? "white" : "black",
                    }}
                  >
                    {sizeObj.size}
                  </button>
                ))}
              </div>
              <button
                className="size-chart"
                onClick={openSizeChart}
                style={{ fontFamily: "Arboria" }}
              >
                SIZE CHART
              </button>
            </div>

            {error && (
              <p style={{ color: "red", fontFamily: "Arboria" }}>
                Please select a size before adding to cart.
              </p>
            )}

            <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
              <button className="add-to-cart-btn" onClick={handleAddToCart}>
                ADD TO CART
              </button>
              <button className="buy-now-btn" onClick={handleBuyNow}>
                BUY NOW
              </button>
            </div>

            <div className="expandable-section">
              <div
                className="section-header"
                onClick={() => setShowDescription(!showDescription)}
              >
                <p style={{ fontFamily: "Arboria" }}>Description</p>
                <span>{showDescription ? <FaAngleUp /> : <FaAngleDown />}</span>
              </div>
              {showDescription && (
                <p
                  className="section-content"
                  style={{ fontFamily: "SF Pro", lineHeight: "15px" }}
                >
                  {productDetails[0]?.short_description}
                </p>
              )}
            </div>

            <div className="expandable-section">
              <div
                className="section-header"
                onClick={() => setShowDetails(!showDetails)}
              >
                <p style={{ fontFamily: "Arboria" }}>Details</p>
                <span>{showDetails ? <FaAngleUp /> : <FaAngleDown />}</span>
              </div>
              {showDetails && (
                <p
                  className="section-content"
                  style={{ fontFamily: "SF Pro", lineHeight: "15px" }}
                >
                  {productDetails[0]?.long_description}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="suggestions-section">
          <h2 className="suggestions-header">You might also like:</h2>
          <div className="suggestions-grid">
            <div className="suggestion-card">
              <img
                src={require("../../Assets/Img/Item.png")}
                alt="Skyline Shirt"
                className="suggestion-image"
              />
              <div className="suggestion-details">
                <p className="suggestion-title">Skyline Shirt</p>
                <p className="suggestion-price">₹ 2,000</p>
              </div>
            </div>
            <div className="suggestion-card">
              <img
                src={require("../../Assets/Img/Item.png")}
                alt="Urban Shirt"
                className="suggestion-image"
              />
              <div className="suggestion-details">
                <p className="suggestion-title">Urban Shirt</p>
                <p className="suggestion-price">₹ 2,000</p>
              </div>
            </div>
            <div className="suggestion-card">
              <img
                src={require("../../Assets/Img/Item.png")}
                alt="Metropolitan Shirt"
                className="suggestion-image"
              />
              <div className="suggestion-details">
                <p className="suggestion-title">Metropolitan Shirt</p>
                <p className="suggestion-price">₹ 2,000</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SizeChartModal isOpen={isModalOpen} onClose={closeSizeChart} />
    </div>
  );
};

export default ProductDetails;
