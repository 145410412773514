import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
    signupData: Cookies.get('signupData') ? JSON.parse(Cookies.get('signupData')) : null,
    loginData: Cookies.get('loginData') ? JSON.parse(Cookies.get('loginData')) : null,
    token: localStorage.getItem('token') || null, 
    bankDetails:Cookies.get('bankDetails') ? JSON.parse(Cookies.get('bankDetails')) : null,
};

export const user_information = createSlice({
    name: "user",
    initialState,
    reducers: {
        setSignupData(state, action) {
            //console.log("...........")
            state.signupData = action.payload;
            //console.log("SignupData in redux: ", state.signupData);
            //console.log("SignupData in redux: ", action.payload);
            Cookies.set('signupData', JSON.stringify(state.signupData)); 
        },
        logout(state) {
            state.loginData = null;
            state.signupData = null; 
            state.token = null;
            Cookies.remove('loginData');
            Cookies.remove("signupData");
            localStorage.removeItem("token");
        },
        setBankDetailsData(state, action) {
            state.bankDetails = action.payload;
            Cookies.set('bankDetails', JSON.stringify(state.bankDetails));
          },
        setLoginData(state, action) {
            state.loginData = action.payload;
            Cookies.set('loginData', JSON.stringify(state.loginData)); 
        },
        setToken(state, action) {
            //console.log("...........")
            //console.log("Action payload (token): ", action.payload);
            state.token = action.payload;
            localStorage.setItem('token', action.payload); 
            //console.log("Token in state: ", state.token);
        },
        clearDataIfInvalid(state) {
            if (!state.signupData || !state.loginData || !state.token) {
                state.signupData = null;
                state.loginData = null;
                state.token = null;
                Cookies.remove('signupData');
                Cookies.remove('loginData');
                localStorage.removeItem('token');
            }
        }
        
    }
});

export const { setSignupData, logout, setLoginData, setToken, setuserId, setAvature,setBankDetailsData,clearDataIfInvalid} = user_information.actions;
export default user_information.reducer;
