import React, { useState } from "react";
import "../Styles/AllCart.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { removeCart } from "../Redux/Cart_System";
import { AiOutlineDelete } from "react-icons/ai";

const AllCart = () => {
  const dispatch = useDispatch();
  const { signupData } = useSelector((state) => state.user);
  const navigation = useNavigate();
  const cartItemsFromRedux = useSelector((state) => state.cart.items);
  const totalItems = useSelector((state) => state.cart.totalItems);
  const [cartItems, setCartItems] = useState(cartItemsFromRedux);

  const total = cartItems.reduce(
    (acc, item) => acc + item.mrp * item.quantity,
    0
  );

  const handleIncrement = (id) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrement = (id) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const handleRemove = (id) => {
    dispatch(removeCart(id)); // Dispatch the removeCart action
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== id)); // Update local cart items
  };

  const handleCheckout = () => {
    // if (signupData) {
      navigation("/checkout", { state: { cartItems } });
    // } else {
    //   navigation("/register");
    // }
  };

  return (
    <div>
      <div className="cart-page">
        <header className="cart-header">
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <img
              style={{ width: "31px" }}
              src={require("../../Assets/Img/solar_cart-outline(1).png")}
              alt="Logo"
            />
            <h2 style={{ fontFamily: "Arboria" }}>Your Cart</h2>
          </div>
          <button
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "transparent",
              fontSize: "28px",
            }}
            onClick={() => navigation("/")}
          >
            &times;
          </button>
        </header>

        <div className="cart-items">
          {cartItems.length === 0 ? (
            <div>
              <div className="cart-content">
                <h2 style={{ fontFamily: "Arboria" }}>YOUR CART IS EMPTY</h2>
                <img
                  src={require("../../Assets/Img/Item.png")}
                  alt="Skyline Shirt"
                  className="product-image1"
                />
                <p className="product-title">SKYLINE SHIRT</p>
              </div>
              <button
                className="start-shopping-button"
                onClick={() => navigation("/AllProduct")}
              >
                START SHOPPING
              </button>
            </div>
          ) : (
            cartItems.map((item) => (
              <div key={item.id} className="cart-item">
                <img
                  src={`https://sianceedu.com/overbar/uploads/product_banner/${item.product_banner}`}
                  alt={item.product_name}
                  className="cart-item-image"
                />

                <div style={{ marginTop: "10px", width: "58%" }}>
                  <div
                    className="cart-item-details"
                    style={{ fontFamily: "Arboria" }}
                  >
                    <h4>{item.product_name}</h4>
                    <p>Size: {item.size}</p>
                  </div>
                  <div style={{display:"flex",alignItems:'center',gap:"10px"}}>
                    <div
                      className="cart-item-quantity"
                      style={{
                        fontFamily: "Arboria",
                        width: "117px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <button onClick={() => handleDecrement(item.id)}>
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button onClick={() => handleIncrement(item.id)}>
                        +
                      </button>
                    </div>
                    <AiOutlineDelete
                      onClick={() => handleRemove(item.id)}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    />
                  </div>
                </div>
                <div
                  className="cart-item-price"
                  style={{ fontFamily: "Arboria" }}
                >
                  ₹ {item.mrp * item.quantity}
                </div>
              </div>
            ))
          )}
        </div>

        {cartItems.length > 0 && (
          <footer className="cart-footer">
            <div className="total-section" style={{ fontFamily: "Arboria" }}>
              <p style={{ fontFamily: "Arboria" }}>ESTIMATED TOTAL</p>
              <h4 style={{ fontFamily: "Arboria" }}>₹ {total}</h4>
            </div>
            <p style={{ textAlign: "left", fontFamily: "Arboria" }}>
              Tax sentence (if any)
            </p>
            <button
              style={{ fontFamily: "Arboria" }}
              className="checkout-btn"
              onClick={handleCheckout}
            >
              CHECK OUT
            </button>
          </footer>
        )}
      </div>
    </div>
  );
};

export default AllCart;
