import React from "react";
import "../Styles/TrackOrder.css";
import { Navbar } from "../Core/Navbar";
import { useForm } from "react-hook-form";

const TrackOrder = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = useForm();

  const onSubmit = async (data) => {
    //console.log(data);
  };
  if (isSubmitSuccessful) {
    reset({
      name: "",
    });
  }

  return (
    <div>
      <Navbar
        color="black"
        src1={require("../../Assets/Img/Vector(1).png")}
        src2={require("../../Assets/Img/material-symbols-light_search(1).png")}
        src3={require("../../Assets/Img/solar_cart-outline(1).png")}
      />
      <div
        className="breadcrumb"
        style={{
          marginBottom: "0px",
          fontFamily: "Arboria",
          display: "flex",
          gap: "6px",
          alignItems: "center",
        }}
      >
        <span>HOME &gt; TRACK MY ORDER</span>
      </div>
      <div className="order-status">
        <h1
          style={{
            fontFamily: "Helvetica Neue",
            fontStyle: "italic",
            fontWeight: "bold",
            fontSize: "36px",
          }}
        >
          where's my order?
        </h1>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="ENTER TRACKING ID"
            className="login-input"
            {...register("name", { required: "name is required" })}
            style={{
              borderColor: "orange",
              marginTop: "13px",
              padding: " 5px 15px",
              width: "280px",
              borderRadius: "10px",
              fontFamily: "Arboria",
            }}
          />
          {errors.name && <p>{errors.name.message}</p>}
        </form>
      </div>
    </div>
  );
};

export default TrackOrder;
